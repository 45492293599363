import request from "@/utils/request";

export function getProperties(page, perPage, searchStr) {
    return request({
        url:
            "/api/properties?perPage=" +
            perPage +
            "&page=" +
            page +
            "&search=" +
            searchStr,
        method: "get",
    });
}
export function getProperty(id) {
    return request({
        url: "/api/properties/" + id,
        method: "get",
    });
}

export function deleteProperty(id) {
    return request({
        url: "/api/properties/" + id,
        method: "delete",
    });
}

export function bulkUploadInstructions() {
    return request({
        url: '/api/bulk/upload/instructions',
        method: 'get',
    })
}

export function bulkUploadProperties(data) {
    return request({
        url: '/api/bulk/upload/properties',
        method: 'post',
        data
    })
}

export function bulkDeleteProperties(data) {
    return request({
        url: '/api/bulk/delete/properties',
        method: 'post',
        data
    })
}

export function deleteImage(id) {
    return request({
        url: "/api/property-image-delete/" + id,
        method: "delete",
    });
}
export function postProperty(data) {
    return request({
        url: "/api/properties",
        method: "post",
        data,
    });
}
export function putProperty(id, data) {
    return request({
        url: "/api/properties/" + id,
        method: "post",
        data,
    });
}

export function getPropertyBySearch(data) {
    return request({
        url: "/api/properties-search",
        method: "post",
        data,
    });
}

export function getLocationData(input, addData, type) {
    return request({
        url: "/api/getlocationdata/" + input + "?adddata=" + addData + "&type=" + type,
        method: "get",
    });
}

export function getPlaceData(placeId) {
    return request({
        url: "/api/getPlaceData/" + placeId,
        method: "get",
    });
}

export function getCalculation(params) {
    return request({
        url: `/api/getCalculation/${params.property.id}/${params.contractLength.id}/${params.layoutDesign.id}`,
        method: "get",
    });
}
